import React from 'react'
import config from 'config'
import { Field, useFieldState, useForm } from 'formular'
import { modalVisibility, ModalVisibilityProps, openModal } from 'modal'
import { useDevice } from 'device'
import cx from 'classnames'

import { useGeoCoder } from 'models/mapLayer'

import { Text } from 'components/dataDisplay'
import { Modal } from 'components/feedback'
import { EditableMap } from 'compositions/map'
import { Button, Input } from 'components/inputs'


type AddressSelectModalProps = {
  address: Field<string>
  lat?: string
  lng?: string
  title?: string
  onClose?: (props: { address: string, lat?: number, lng?: number }) => void
}

const AddressSelectModal: React.FC<AddressSelectModalProps & ModalVisibilityProps> = (props) => {
  const { address, lat, lng, title, onClose, closeModal } = props

  const { value } = useFieldState(address)

  const { isMobile } = useDevice()

  const mapForm = useForm<EditableMap.MapForm>({
    fields: {
      placemarks: {
        value: [],
      },
    },
    initialValues: {
      placemarks: [
        {
          id: 0,
          geometry: lat && lng ? [ Number(lat), Number(lng) ] : config.cityCenter.latLng,
        },
      ],
    },
  })

  useGeoCoder({
    addressField: address,
    placemarksField: mapForm.fields.placemarks,
  })

  const placemarks = useFieldState(mapForm.fields.placemarks)
  const center = placemarks.value[0]?.geometry || config.cityCenter.latLng

  return (
    <Modal
      title={title || 'Адрес на карте'}
      width={isMobile ? 294 : 618}
      fullWidth
      closeModal={closeModal}
    >
      <Input
        className="mt-32px mb-16px"
        field={address}
        label="Поиск по адресу"
        icon="main/search_16"
      />
      <div className="flex flex-col justify-center">
        <div
          className="relative"
          style={{ height: `500px`, overflow: 'hidden' }}
        >
          {
            __CLIENT__ && (
              <EditableMap
                className="radius-8 overflow-hidden"
                form={mapForm}
                center={center}
                zoom={15}
              />
            )
          }
        </div>
        <Text
          className={cx( 'mt-6px', { 'opacity' : !value })}
          size="t16-20"
          message={ value || 'Адрес не указан' }
        />
        <div style={{ height: '104px' }} />
        <div className="fixed shadow-titanic-1 w-full bottom-0 left-0 z-800 bg-white">
          <div className={cx( isMobile ? 'mw-276' : 'mw-584', 'pt-32px pb-32px margin-auto')}>
            <Button
              title="Сохранить"
              style="primary"
              disabled={!value}
              size={40}
              fullWidth
              onClick={() => {
                if (typeof onClose === 'function') {
                  onClose({
                    address: value,
                    lat: placemarks.value[0]?.geometry?.[0],
                    lng: placemarks.value[0]?.geometry?.[1],
                  })
                }

                closeModal()
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const openAddressSelectModal = (props: AddressSelectModalProps) => openModal('addressSelectModal', props)


export default modalVisibility('addressSelectModal', AddressSelectModal)